import {
    BooleanInput,
    Edit,
    EmailField,
    FunctionField,
    ReferenceField,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import * as React from "react";
import {SanitizedGrid} from "../components/SanitizedElements";
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";
import {HostedFileField} from "../components/HostedFile";
import SendReceiptButton from "./SendReceiptButton";
import {getIterationName} from "../iterations/utils";

const DonationTitle = ({ record }) => (
    <span>A editar {record ? `"${record.name} [${getIterationName(record.course_iteration, true)}]"` : ''}</span>
);

const DonationEditActions = ({data }) => (
    <TopToolbar>
        {data &&
            <ShowButton basePath="/users" label="Página do utilizador" record={{id: data.user_id}}/>
        }
        <SendReceiptButton label="Enviar Fatura" />
    </TopToolbar>
);

export const DonationsEdit = ({permissions, in_list = false, ...props}) => {
    return (<Edit {...props} title={<DonationTitle/>} actions={in_list ? null : <DonationEditActions permissions={permissions}/>}>
        <SimpleForm
            toolbar={<ToolbarWithoutDelete />}
        >
            <SanitizedGrid container spacing={1} style={{width: "100%"}}>
                <SanitizedGrid item xs={6}>
                    <SimpleShowLayout>
                        <ReferenceField source="course_iter_id" reference="iterations" link="show">
                            <FunctionField render={record => getIterationName(record, true)}/>
                        </ReferenceField>
                        <TextField fullWidth source="name"/>
                        <ReferenceField link={false} fullWidth source="user_id" reference="users">
                            <EmailField fullWidth source="email"/>
                        </ReferenceField>
                        <TextField fullWidth source="parent_name"/>
                        <TextField fullWidth source="parent_phone"/>
                        <EmailField fullWidth source="parent_email"/>
                    </SimpleShowLayout>
                </SanitizedGrid>
                <SanitizedGrid item xs={6}>
                    <SimpleShowLayout>
                        <HostedFileField source="donation_receipt" />
                        <TextField source="vat_number" />
                    </SimpleShowLayout>
                    <TextInput multiline fullWidth source="confirmation_obs"/>
                    <TextInput type="number" fullWidth source="donation_amount" />
                    <BooleanInput source="donation_validated" />
                </SanitizedGrid>
            </SanitizedGrid>
        </SimpleForm>
    </Edit>)
};