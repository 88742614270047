import {Labeled, Loading, useGetOne} from "react-admin";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import {Chip} from "@material-ui/core";

export const ApplicationQuestions = ({ record, source }) => {
    
    const is_aso = record?.course_iteration?.course.program.slug === 'aso';

    const { data, loading, error } = useGetOne(
        is_aso ? 'iterations' : 'application_specs',
        is_aso ? record['course_iter_id'] : record['application_spec_id']
    );

    if (loading) { return <Loading />; }

    const questions = is_aso ? data?.application_questions : data?.questions;
    if(error) { return <Labeled label="ra.page.error" />; }
    if(!questions) { return <Labeled label="resources.application_submissions.no_questions_defined" />; }

    const answers = record[source];

    return questions.map(section => (
        <div key={section["title"]}>
            <Typography variant="h6" gutterBottom>
                {section["title"]}
            </Typography>
            {
                section["fields"].map(field => {
                    const answer = answers?.[field["id"]];
                    const renderAsTags = answer && 
                        (field["type"] === "tags" || (Array.isArray(answer) && answer.length > 1));
                    
                    return (
                        <Labeled key={field["id"]} label={field["label"]}>
                            <span>
                                {renderAsTags ? (
                                    answer.map(tag => (<Chip key={tag} label={tag} style={{ marginRight: '4px' }} />))
                                ) : (
                                    answer
                                )}
                            </span>
                        </Labeled>
                    );
                })
            }
        </div>
    ));
};
