import {
    Create,
    Edit,
    FormTab,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin'
import {useState, useEffect} from 'react';

import {getApplicationSpecName} from "./utils";
import {slugify} from '../utils';
import {JSONInput} from "../components/JsonFieldInput";
import {DateTimeInput} from '../components/date-time-pickers/';
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";
import {SanitizedGrid} from '../components/SanitizedElements';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFormState, useForm } from 'react-final-form';


const ApplicationSpecTitle = ({ record }) => {
    return <span>A editar candidatura "{getApplicationSpecName(record)}"</span>;
};

const SelectProgramNoASO = ({choices, ...rest}) => {
    const asoChoice = choices?.find(choice => choice.slug === 'aso');
    if (asoChoice) {
        asoChoice.disabled = true;
    }
    return <SelectInput choices={choices} {...rest} />
}

const EditionAndSlugInput = () => {
    const form = useForm();
    const formData = useFormState();
    const [generateSlug, setGenerateSlug] = useState(
        !formData.values.slug || formData.values.slug === slugify(formData.values.edition)
    );

    useEffect(() => {
        form.change('slug', generateSlug ? slugify(formData.values.edition) : formData.values.slug);
    }, [generateSlug, form, formData.values.edition, formData.values.slug]);

    return (
        <SanitizedGrid container spacing={1} style={{width: "100%"}}>
            <SanitizedGrid item xs={8}>
                <TextInput 
                    fullWidth 
                    source="edition" 
                    validate={required()} 
                />
            </SanitizedGrid>
            <SanitizedGrid item xs={4}>
                <div style={{ position: 'relative' }}>
                    <TextInput
                        fullWidth
                        source="slug" 
                        validate={required()}
                        onChange={() => setGenerateSlug(false)}
                        onBlur={(e) => {
                            if (!generateSlug && !e.target.value) {
                                form.change('slug', formData.initialValues.slug);
                            }
                        }}
                    />
                    <FormControlLabel
                        style={{position: 'absolute', right: '0', transform: 'translateY(25%)'}}
                        control={<Checkbox checked={generateSlug} onChange={(e) => setGenerateSlug(e.target.checked)} />}
                        label="Auto"
                    />
                </div>
            </SanitizedGrid>
        </SanitizedGrid>
    );
};

const questionsSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            title: {
                type: 'string'
            },
            description: {
                type: 'string'
            },
            fields: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'string'
                        },
                        label: {
                            type: 'string'
                        },
                        description: {
                            type: 'string'
                        },
                        type: {
                            type: 'string',
                            "enum" : ["text", "number", "email", "phone", "area", "date", "select",
                                "select_other", "select_other_number", "tags", "check", "radio",
                                "file", "image"]
                        },
                        multiple: {
                            type: 'boolean'
                        },
                        required: {
                            type: 'boolean'
                        },
                        max: {
                            type: 'number'
                        },
                        options: {
                            type: 'array',
                            items: {
                                type: 'string'
                            }
                        }
                    },
                    additionalProperties: false,
                    required: ["id", "label", "type"],
                }
            }
        },
        required: ["title", "fields"],
        additionalProperties: false,
    },
};


const editionForm = edit => (
    <TabbedForm toolbar={<ToolbarWithoutDelete />}>
        <FormTab label="resources.application_specs.edition_tab_general">
            {edit &&
                <TextInput fullWidth disabled source="id" />
            }
            <ReferenceInput fullWidth source="program_id" reference="programs" sort={{ field: 'name', order: 'ASC' }}>
                <SelectProgramNoASO validate={required()} />
            </ReferenceInput>
            <EditionAndSlugInput />
            <DateTimeInput fullWidth source="start_date" />
            <DateTimeInput fullWidth source="end_date" />
        </FormTab>
        <FormTab label="resources.application_specs.edition_tab_format">
            <JSONInput source="questions" schema={questionsSchema}/>
        </FormTab>
    </TabbedForm>
);

export const ApplicationSpecEdit = props => (
    <Edit {...props} title={<ApplicationSpecTitle />}>
        {editionForm(true)}
    </Edit>
);

export const ApplicationSpecCreate = props => (
    <Create {...props}>
        {editionForm(false)}
    </Create>
);
