import {
    BooleanField,
    Datagrid,
    FunctionField,
    List,
    NullableBooleanInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    useTranslate
} from 'react-admin';
import {Box} from '@material-ui/core';
import * as React from "react";
import ApplicationShow from "./show";
import BulkAcceptButton from "./BulkAcceptButton";
import BulkRejectButton from "./BulkRejectButton";
import BigPagination from "../components/BigPagination";
import {applicationExporter} from "./ApplicationExporter";
import BulkSendLoginsButton from "./BulkSendLoginsButton";
import {getIterationName} from "../iterations/utils";
import {getApplicationSpecName} from "../application_specs/utils";


export const ApplicationsBulkActionButtons = (props) => (
    <React.Fragment>
        <BulkAcceptButton {...props} />
        <BulkRejectButton {...props} />
        <BulkSendLoginsButton {...props} />
    </React.Fragment>
);

const SentEmailsField = (props) => {
    const translate = useTranslate();
    const selectionLabel = translate('resources.applications.fields.selection_email');
    const nr_logins = props.record?.logins_emails_sent || 0;
    const loginsLabel = translate('resources.applications.fields.logins_email') + (nr_logins > 0 ? ' (' + nr_logins + ')' : '');

    return (
    <Box display="flex" alignItems="center">
        <Box display="flex" mr={1}>
            S {<BooleanField source="email_sent" valueLabelTrue={selectionLabel} valueLabelFalse={selectionLabel} />}
        </Box>
        <Box display="flex">
            L {<BooleanField source="logins_emails_sent" looseValue={true} valueLabelTrue={loginsLabel} valueLabelFalse={loginsLabel} />}
        </Box>
    </Box>
    );
};

export const applicationFilters = [
    <ReferenceInput perPage={1000} source="course_iter_id" reference="iterations" sort={{ field: 'start_date', order: 'DESC' }}>
        <SelectInput
            optionText={(choice) =>
                choice.id // the empty choice is { id: '' }
                    ? getIterationName(choice, true, false)
                    : ''
            }
        />
    </ReferenceInput>,
    <SelectInput
        source="school_district"
        choices={["Açores", "Aveiro", "Beja", "Braga", "Bragança", "Castelo Branco", "Coimbra", "Évora", "Faro",
            "Guarda", "Leiria", "Lisboa", "Madeira", "Portalegre", "Porto", "Santarém", "Setúbal", "Viana do Castelo",
            "Vila Real", "Viseu", "Outro"].map(d => ({id: d, name: d}))}
    />,
    <SelectInput
        source="school_year"
        choices={["5º ano", "6º ano", "7º ano", "8º ano", "9º ano", "10º ano", "11º ano", "12º ano", "Outro"].map(d => ({id: d, name: d}))}
    />,
    <NullableBooleanInput source="accepted"/>,
    <NullableBooleanInput source="confirmed"/>,
    <TextInput source="user_id" parse={v => v.split(/\s+/)}/>
];

export const ApplicationDatagrid = (props) => (
    <Datagrid rowClick="show" expand={<ApplicationShow in_list="true"/>} {...props}>
        <ReferenceField source="application_spec_id" reference="application_specs" link="show" emptyText={'After School Online'}>
            <FunctionField render={record => getApplicationSpecName(record)} />
        </ReferenceField>
        {!props.hide_course &&
            <ReferenceField source="course_iter_id" reference="iterations" link="show">
                <FunctionField render={record => getIterationName(record, true, false)} />
            </ReferenceField>
        }
        <TextField source="name" />
        <TextField source="school_district"/>
        <TextField source="school_name"/>
        <TextField source="school_year"/>
        <BooleanField source="accepted" label="Sel."/>
        <BooleanField source="confirmed" label="Conf."/>
        <SentEmailsField label="Emails" />
    </Datagrid>
);

const ResourceList = props => (
    <List {...props}
          sort={{ field: 'created_at', order: 'DESC' }}
          bulkActionButtons={<ApplicationsBulkActionButtons />}
          filters={applicationFilters}
          pagination={<BigPagination />}
          exporter={applicationExporter}
    >
        <ApplicationDatagrid />
    </List>
);



export default ResourceList;