import { useRecordContext, useResourceContext, SimpleShowLayout, Show, TextField, Tab, TabbedShowLayout } from 'react-admin';
import { getApplicationSpecName } from './utils';
import LcDateField from '../components/LcDateField';
import ReferenceList from "../components/ReferenceList";
import { ApplicationSubmissionsDatagrid, submissionFilters } from '../application_submissions/list';
import ListCustomActions from "../components/ListCustomActions";
import SendRejectionEmailsButton from "./SendRejectionEmailsButton";

const ApplicationSpecTitle = ({ record }) => {
    return <span>Especificação "{getApplicationSpecName(record)}"</span>;
};

const Aside = () => {
    const record = useRecordContext();
    const resource = useResourceContext();

    return record ? (
        <SimpleShowLayout record={record} resource={resource}>
            <TextField source="program.name" />
            <TextField source="edition" />
            <TextField source="slug" />
            <LcDateField source="start_date" />
            <LcDateField source="end_date" />
        </SimpleShowLayout>
    ) : null;
};

const ApplicationSpecShow = ({permissions, ...props}) => {
    return (
    <Show
        aside={<Aside />}
        {...props}
        component="div"
        title={<ApplicationSpecTitle />}
    >
        <TabbedShowLayout>
            <Tab label="resources.application_specs.submissions">
            <ReferenceList
                    reference="application_submissions"
                    target="application_spec_id"
                    sort={{field: 'created_at', order: 'desc'}}
                    filters={submissionFilters}
                    actions={<ListCustomActions><SendRejectionEmailsButton /></ListCustomActions>}
                    exporter={false}
                >
                <ApplicationSubmissionsDatagrid in_show="true" />
            </ReferenceList>
            </Tab>
        </TabbedShowLayout>   
    </Show>
    );
};

export default ApplicationSpecShow;