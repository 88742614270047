import {downloadCSV} from "react-admin";
import jsonExport from 'jsonexport/dist';
import DateFnsAdapter from "@date-io/date-fns";
import defaultLocale from "date-fns/locale/pt";
import { getIterationName } from "../iterations/utils";

const dateFns = new DateFnsAdapter({locale: defaultLocale});

export const applicationExporter = (applications, _, dataProvider) => {

    const max_per_req = 45;

    const get_request_promises = (list, resource) => {
        // removes duplicates
        // divides into chunks of at maximum "max_per_req" elements
        // calls getManyReference passing each of the chunks
        // concats the whole data
        return Promise.all([...new Set(list)].map(function(e, i){
            return i % max_per_req === 0 ? list.slice(i, i + max_per_req) : null;
        }).filter(function(e){ return e; }).map(group =>
            dataProvider.getManyReference(
                resource,
                {
                    target: 'user_id',
                    id: group,
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'created_at', order: 'DESC' },
                }
            )
        )).then(allRes =>
            allRes.reduce(function (flat, toFlatten) {
                return flat.concat(toFlatten.data);
            }, [])
        );
    };

    const intoUserDict = data => {
        let userDict = {};

        data.forEach(x => {
            if (!(x.user_id in userDict))
                userDict[x.user_id] = [];
            userDict[x.user_id].push(x);
        });

        return userDict;
    }

    const application_ids = applications.map(a => a.user_id);


    get_request_promises(
        application_ids,
        'applications'
    ).then(res => {
        const prev_applications = intoUserDict(res);

        get_request_promises(
            application_ids,
            'staff_users'
        ).then(res => {
            const prev_staff = intoUserDict(res);

            const iterationString = x => getIterationName(x, true) + ` [${dateFns.formatByString(new Date(x.created_at), "MMMM 'de' yyyy")}]`;

            const applicationsForExport = applications.map(appl => {
                appl.course_name = getIterationName(appl.course_iteration, true);

                appl.student_history = (appl.user_id in prev_applications) ? prev_applications[appl.user_id].filter(x => x.course_iter_id !== appl.course_iter_id).map(x =>
                    `${iterationString(x)} (${x.accepted ? "Foi aluno" : "Candidatou-se"}) ${x.comment ? 'Comentário: ' + x.comment : ''} ${x.recommendation && x.recommendation !== 'neutro' ? 'Recomendação: ' + x.recommendation : ''}`
                ).join("\n") : null;

                appl.staff_history = (appl.user_id in prev_staff) ? prev_staff[appl.user_id].map(x =>
                    `${iterationString(x)}: ${x.roles.join(";")}`
                ).join("\n") : null;

                // filter out undesired fields
                const {accepted, comment, confirmation_obs, confirmed, course_iteration, donation_amount, donation_receipt, email_sent, id_card_image, recommendation, updated_at, vat_number, id, ...applExport} = appl;

                return applExport;
            });

            jsonExport(applicationsForExport, {
                headers: [
                    // Contacts and course/user ids
                    'created_at', 'course_iter_id', 'course_name', 'user_id', 'name', 'user.email', 'phone', 'parent_name', 'parent_email', 'parent_phone',
                    // user details/personal data
                    'birthdate', 'gender', 'nationality', 'school_year', 'school_name', 'school_group', 'school_location', 'school_district',
                    // history
                    'student_history', 'staff_history', 'history_t2', 'history_t2_app', 'history_oc',
                    // info
                    'info_interests', 'info_english', 'info_computer', 'info_internet',
                ] // order fields in the export
            }, (err, csv) => {
                downloadCSV(csv, 'applications'); // download as 'posts.csv` file
            });
        });
    });
};