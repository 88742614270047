import {
    BooleanField,
    Datagrid,
    EmailField,
    FunctionField,
    ReferenceField,
    Show,
    ShowButton,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    useRecordContext
} from 'react-admin';
import * as React from "react";

import PrefixedUrlField from "../components/PrefixedUrlField";
import ReferenceList from "../components/ReferenceList";
import {SessionAttendancesBulkActionButtons} from "../sessions/show";
import SetAbsentButton from "../sessions/SetAbsentButton";
import SetPresentButton from "../sessions/SetPresentButton";
import SendCertificateButton from "./SendCertificateButton";
import {getIterationName} from "../iterations/utils";

const ParticipantTitle = ({ record }) => { console.log("record", record); return (
    <span>Participante {record ? `"${record.name} [${getIterationName(record.course_iteration, true)}]"` : ''}</span>
);}

// TODO add button to change student name
const ParticipantActions = ({ permissions, data, ...props}) => (
    <TopToolbar>
        {permissions && permissions.isAdmin() && data &&
            <ShowButton basePath="/users" label="Página do utilizador" record={{id: data.user_id}}/>
        }
        <SendCertificateButton label="Enviar certificado" {...props} />
    </TopToolbar>
);

const AttendancesList = () => {
    const record = useRecordContext();

    const ShowSessionButton = ({record}) => {
        return record ? <ShowButton basePath="/sessions" record={{id: record.session_id}} label="Ver Sessão"/> : null
    }

    return record ? (
        <ReferenceList
            record={{id: record.user_id}}
            reference="session_participants"
            target="user_id"
            sort={{field: 'session_number', order: 'asc'}}
            filter={{course_iter_id: record.course_iter_id}}
            bulkActionButtons={<SessionAttendancesBulkActionButtons />}
            actions={null}
        >
            <Datagrid>
                <TextField source="session_number" />
                <FunctionField sortable={false} source="attended" label="Presente" render={(record,source) =>
                    <BooleanField record={{...record, attended:!!record.attended_session_id}} source={source}/>}
                />
                <ShowSessionButton />
                <SetPresentButton />
                <SetAbsentButton />
            </Datagrid>
        </ReferenceList>
    ) : null;
}

const ResourceShow = ({permissions, ...props}) => (
    <Show
        {...props}
        component="div"
        title={<ParticipantTitle/>}
        actions={<ParticipantActions permissions={permissions} />}
    >
        <TabbedShowLayout>
            <Tab label="Geral">
                <ReferenceField source="course_iter_id" reference="iterations" link={"show"}>
                    <FunctionField render={record => getIterationName(record, true)} />
                </ReferenceField>
                <TextField fullWidth disabled source="name" />
                {permissions && permissions.isAdmin() &&
                    <ReferenceField link={false} fullWidth label="Email" source="user_id" reference="users">
                        <EmailField fullWidth source="email" />
                    </ReferenceField>
                }
            </Tab>
            <Tab label="Presenças">
                <AttendancesList />
            </Tab>
            <Tab label="Discord">
                <FunctionField source="in_discord" label="No discord" render={(record,source) =>
                    <BooleanField record={{...record, in_discord:!!record.discord_user_id}} source={source}/>}/>
                <PrefixedUrlField source="invite_code" label="Invite pessoal discord" prefix="https://discord.gg/"/>
                <TextField source="discord_user_id"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ResourceShow;
